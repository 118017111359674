import "../Style/App.css"
import "./Style/Building.css"

function Building({ item }) {

   return (
      <div className="News" id="yangi">
         <div className="container">
            <h1 className="Prime_Text">Sergeli tuman politexnikumi rahbarlari</h1>
            <div className="Cards" style={{ width: "100%" }}>
               <div className="g_Card">
                  {item.map((element, index) => (
                     <div style={{ height: "500px!important" }} className="card" key={index}>
                        <img className="image__building" alt="" src={element.img}/>
                        <div className="content">
                           <span className="Data__Text">{element.data}</span>
                           <div className="Secondary_TextsDiv" >
                              {element.text}
                           </div>
                           <h1>{element.title}</h1>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
            <button className="btn_prime" onClick={(e) => { e.target.parentNode.children[1].children[1].style.display = "block" }}>Ko'proq ko'rish</button>
         </div>
      </div>
   );
}
export default Building;  