import "../Style/App.css"
import "./Style/Workers.css"

function Workers() {
   return (
      <div className="Workers" id="about_us">
         <div className="container">
            <div className="content">
               <h1 className="Prime_Text">Biz Haqimizda</h1>
               <h3 className="Secondary_Text" style={{ width: "100%" }}>Sergeli tuman politexnikumi O‘zbekiston Respublikasi Prezidentining 
                  <b> 2024-yil 16-oktabrdagi</b> “Kasbiy ta’limda malakali kadrlar tayyorlash tizimini yanada takomillashtirish va xalqaro ta’lim
                  dasturlarini joriy qilish chora-tadbirlari to‘g‘risida”gi PF-158-son Farmoniga asosan (sobiq Sergeli tuman kasb-hunar
                  maktabi o‘rnida) tashkil etilgan. Ta’lim muassasining quvvati <b>720 o‘rinli</b> bo‘lib, hozirgi kunda <b>825 nafar</b> o‘quvchi tahsil olmoqda.
                  Politexnikumda <b>jami 77 nafar pedagog</b> xodimlar faoliyat olib bormoqda. Ulardan 54 nafari oliy ma’lumotli, 9 nafari to‘liqsiz
                  oliy va 14 nafari o‘rta maxsus ma’lumotli o‘qituvchilardan iborat. Toifasi bo‘yicha 3 nafar yetakchi o‘qituvchi,
                  12 nafar kata o‘qituvchi, 39 nafar oliy ma’lumotli va 23 nafar o‘rta maxsus ma’lumotli malaka toifasiga ega.</h3>
            </div>
         </div>
      </div>
   );
}
export default Workers;  