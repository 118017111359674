import "../Style/App.css"
import "./Style/News.css"

function News({ item }) {
   return (
      <div className="News" id="yangi">
         <div className="container">
            <h1 className="Prime_Text">Yangiliklar</h1>
            <div className="Cards" style={{ width: "100%" }}>
               <div className="g_Card">
                  {item.map((element, index) => (
                     <div className="card" key={index}>
                        <img style={{ height: "300px!important" }} src={element.img} alt="" />
                        <div className="content">
                           <span className="Data__Text">{element.data}</span>
                           <h1>{element.title}</h1>
                           <div className="Secondary_TextsDiv" >
                              {element.text}
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
            <button className="btn_prime" onClick={(e) => { e.target.parentNode.children[1].children[1].style.display = "block" }}>Ko'proq ko'rish</button>
         </div>
      </div>
   );
}

export default News;