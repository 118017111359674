import "../Style/App.css";
import "./Style/Contact.css";

function Contact() {
   return (
      <div className="Contact" id="contact">
         <div className="container">
            <div className="content">
               <h1 className="Prime_Text">Biz bilan bog'laning</h1>
               <h3 className="Secondary_Text">Biz siz bilan 24 soat aloqadamiz</h3>
               <div className="contact">
                  <h2 className="Text_32">Bizga ulaning</h2>
                  <div className="social">
                     {/* <a href="https://t.me/Uchtepa_295maktab_axil_oila"><i className="fab fa-facebook"></i>Facebook</a> */}
                     {/* <a href="https://t.me/Uchtepa_295maktab_axil_oila"><i className="fab fa-instagram-square"></i>Instagram</a> */}
                     <a href="https://t.me/Sh_A_Tashev_direktor"><i className="fab fa-telegram"></i>Telegram</a>
                     <a href="tel:+998712906111"><i className="fas fa-phone-square"></i>+998-71-290-61-11</a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
export default Contact;  