import "./Style/App.css"
import Navbar from "./components/Navbar.jsx"
import About from "./components/About.js";
import News from "./components/News.js";
import Workers from "./components/Workers.js";
import Childreen from "./components/Childreen.js";
import Building from "./components/Building.js";
import Contact from "./components/Contact.jsx";
import Footer from "./components/Footer.jsx";
import { useEffect, useState } from "react";
import o1 from "./images/o1.jpg";
import o2 from "./images/o2.jpg";
import o3 from "./images/o3.jpg";
import o4 from "./images/o4.jpg";
import u2 from "./images/u2.jpg";
import u3 from "./images/u3.jpg";
import r1 from "./images/1.png";
import r2 from "./images/r2.png";
import r3 from "./images/r3.png";
import r4 from "./images/r4.png";
import ro1 from "./images/ro1.jpg";
import ro2 from "./images/ro2.jpg";
import ro3 from "./images/ro3.jpg";
import ro4 from "./images/ro4.jpg";
import ro5 from "./images/ro5.jpg";
import ro6 from "./images/ro6.jpg";
import ro7 from "./images/ro7.jpg";
import ro8 from "./images/ro8.jpg";
import ro9 from "./images/ro9.jpg";

function App() {

   // const url = "http://128.199.27.57";

   const [item, setitem] = useState([])
   const [item2, setitem2] = useState([])
   const [newsdata, setnews] = useState([])
   const [gal, setgal] = useState([])

   const fetchingData = async () => {
      // let teachers = await axios.get("https://api.sergelikasbhunar.uz/api/teachers?append=imagePath&sort=-sort")
      // let students = await axios.get("https://api.sergelikasbhunar.uz/api/students?append=imagePath&sort=-sort")
      // let posts = await axios.get(url)
      // let galery = await axios.get("https://api.sergelikasbhunar.uz/api/galleries")
      let teachers = {}
      let students = {
         data: [
            { img: ro1 },
            { img: ro2 },
            { img: ro3 },
            { img: ro4 },
            { img: ro5 },
            { img: ro6 },
            { img: ro7 },
            { img: ro8 },
            { img: ro9 },
         ]
      }
      let posts = {
         data: [
            {
               id: 1,
               title: "“Iste’dodli yoshlar” tadbiri",
               text: "2024-yil 13-dekabr kuni Sergeli tuman politexnikumida “Iste’dodli yoshlar” tadbiri bo‘lib o‘tdi. Unda Oshpaz, Sartarosh va Tikuvchi kasbi bo‘yicha tahsil olayotgan iste’dodli o‘quvchilar tomonidan tayyorlangan amaliy ishlanmalar namoyish qilindi. Tadbirda so‘z olgan muassasa direktori Sh.Tashev  mamlakatimizda iqtidorli yoshlarning tashabbuslarini qo‘llab-quvvatlash borasida amalga oshirilayotgan ishlar xususida gapirdi. Jumladan yoshlarni ilmiy-texnik faoliyatga keng jalb qilish, iqtidorli yoshlarning bilim, ko‘nikma va malakasini oshirish, innovatsion g‘oyalarini amaliyotga tatbiq etish hamda hayotda o‘z o‘rnini topishiga ko‘maklashish tizimli ishlar olib borilayotganligi aytildi. ",
               img: o1,   
            },
            {
               id: 1,
               title: "“Iste’dodli yoshlar” tadbiri",
               text: "2024-yil 13-dekabr kuni Sergeli tuman politexnikumida “Iste’dodli yoshlar” tadbiri bo‘lib o‘tdi. Unda Oshpaz, Sartarosh va Tikuvchi kasbi bo‘yicha tahsil olayotgan iste’dodli o‘quvchilar tomonidan tayyorlangan amaliy ishlanmalar namoyish qilindi. Tadbirda so‘z olgan muassasa direktori Sh.Tashev  mamlakatimizda iqtidorli yoshlarning tashabbuslarini qo‘llab-quvvatlash borasida amalga oshirilayotgan ishlar xususida gapirdi. Jumladan yoshlarni ilmiy-texnik faoliyatga keng jalb qilish, iqtidorli yoshlarning bilim, ko‘nikma va malakasini oshirish, innovatsion g‘oyalarini amaliyotga tatbiq etish hamda hayotda o‘z o‘rnini topishiga ko‘maklashish tizimli ishlar olib borilayotganligi aytildi. ",
               img: o2,   
            },
            {
               id: 1,
               title: "“Iste’dodli yoshlar” tadbiri",
               text: "2024-yil 13-dekabr kuni Sergeli tuman politexnikumida “Iste’dodli yoshlar” tadbiri bo‘lib o‘tdi. Unda Oshpaz, Sartarosh va Tikuvchi kasbi bo‘yicha tahsil olayotgan iste’dodli o‘quvchilar tomonidan tayyorlangan amaliy ishlanmalar namoyish qilindi. Tadbirda so‘z olgan muassasa direktori Sh.Tashev  mamlakatimizda iqtidorli yoshlarning tashabbuslarini qo‘llab-quvvatlash borasida amalga oshirilayotgan ishlar xususida gapirdi. Jumladan yoshlarni ilmiy-texnik faoliyatga keng jalb qilish, iqtidorli yoshlarning bilim, ko‘nikma va malakasini oshirish, innovatsion g‘oyalarini amaliyotga tatbiq etish hamda hayotda o‘z o‘rnini topishiga ko‘maklashish tizimli ishlar olib borilayotganligi aytildi. ",
               img: o3,   
            },
            {
               id: 1,
               title: "“Iste’dodli yoshlar” tadbiri",
               text: "2024-yil 13-dekabr kuni Sergeli tuman politexnikumida “Iste’dodli yoshlar” tadbiri bo‘lib o‘tdi. Unda Oshpaz, Sartarosh va Tikuvchi kasbi bo‘yicha tahsil olayotgan iste’dodli o‘quvchilar tomonidan tayyorlangan amaliy ishlanmalar namoyish qilindi. Tadbirda so‘z olgan muassasa direktori Sh.Tashev  mamlakatimizda iqtidorli yoshlarning tashabbuslarini qo‘llab-quvvatlash borasida amalga oshirilayotgan ishlar xususida gapirdi. Jumladan yoshlarni ilmiy-texnik faoliyatga keng jalb qilish, iqtidorli yoshlarning bilim, ko‘nikma va malakasini oshirish, innovatsion g‘oyalarini amaliyotga tatbiq etish hamda hayotda o‘z o‘rnini topishiga ko‘maklashish tizimli ishlar olib borilayotganligi aytildi. ",
               img: o4,   
            },
            {
               id: 1,
               title: "Uchrashuvlar",
               text: "O‘zbekiston Respublikasi Prezidenti “Kasbiy ta’limda malakali kadrlar tayyorlash tizimi yanada takomillashtirish va xalqaro ta'lim dasturini joriy qilish to‘g‘risida” 2024-yil 16-oktabrdagi PF-158-son Farmoniga muvofiq kasbiy ta'lim tashkilotlari Oliy ta’lim muassasalariga biriktirilgan. Shu munosabat bilan kasbiy ta’lim tashkilotlari faoliyatini yanada rivojlantirish, dual ta’lim shaklida kadrlar tayyorlash hamda ish beruvchilar bilan hamkorlikni yanada kuchaytirish bo‘yicha 2024-yil 28-noyabr kuni Toshkent arxitektura- qurilish universiteti va Sergeli tuman politexnikumi rahbarlari uchrashishdi.Mazkur uchrashuvda universitet va politexnikum o‘rtasida faoliyatni rivojlantirish uchun muhokamalar qilindi va yo‘l xaritasi tuzildi.",
               img: u2,   
            },
            {
               id: 1,
               title: "Uchrashuvlar",
               text: "O‘zbekiston Respublikasi Prezidenti “Kasbiy ta’limda malakali kadrlar tayyorlash tizimi yanada takomillashtirish va xalqaro ta'lim dasturini joriy qilish to‘g‘risida” 2024-yil 16-oktabrdagi PF-158-son Farmoniga muvofiq kasbiy ta'lim tashkilotlari Oliy ta’lim muassasalariga biriktirilgan. Shu munosabat bilan kasbiy ta’lim tashkilotlari faoliyatini yanada rivojlantirish, dual ta’lim shaklida kadrlar tayyorlash hamda ish beruvchilar bilan hamkorlikni yanada kuchaytirish bo‘yicha 2024-yil 28-noyabr kuni Toshkent arxitektura- qurilish universiteti va Sergeli tuman politexnikumi rahbarlari uchrashishdi.Mazkur uchrashuvda universitet va politexnikum o‘rtasida faoliyatni rivojlantirish uchun muhokamalar qilindi va yo‘l xaritasi tuzildi.",
               img: u3,   
            },
         ]
      }
      let galery = {
         data: [
            {
               id: 1,
               title: "Tashev Shavkat Abdurasulovich",
               text: "Lavozimi: Direktor",
               img: r1,
            },
            {
               id: 1,
               title: "Bozorov Muhiddin Urazboyevich",
               text: "Lavozimi: O`quv ishlari bo`yicha direktor orinboasari",
               img: r2,
            },
            {
               id: 1,
               title: "Yorboboyev Anvar Qodirovich",
               text: "Lavozimi: Yoshlar bilan ishlash bo`yicha direktor o`rinbosari",
               img: r3,
            },
            {
               id: 1,
               title: "Xudoynazarov Ilyos Qayumovich",
               text: "Lavozimi: Ishlab chiqarish ta`limi bo`yicha direktor o`rinbosari",
               img: r4,
            },
         ]
      }
      
      setitem(teachers?.data?.data);
      setitem2(students?.data);
      setnews(posts?.data);
      setgal(galery?.data);
   }
   useEffect(() => {
      fetchingData()
   }, [])
   return (
      <div className="App">
         <Navbar />
         <About />
         <News item={newsdata}/>
         <Workers item={item} />
         <Building item={gal} />
         <Childreen item={item2} />
         <Contact />
         <Footer />
      </div>
   );
}


export default App;  
