import "../Style/App.css"
import "./Style/Workers.css"

function Workers(props) {
   return (
      <div className="Workers" id="child">
         <div className="container">
            <div className="content">
               <h1 className="Prime_Text">Faxrli o'quvchilarimiz</h1>
            </div>
            <div className="galery__main">
               {props?.item?.map((element, index) => {
                  return (
                     <img key={index} src={element?.img} alt="BMW M5 CS" />
                  )
               })}
            </div>
         </div>
      </div>
   );
}
export default Workers;  